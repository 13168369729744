// Button fuer mobile menu
.mobileMenu {
    display: block;
  &__Box {
    position: absolute;
    top: 40px;
    right: 20px;
    width: 33px;
    height: 18px;
    outline: none;
    background-color: transparent;
    border: 0;
    z-index: 99999;
  }
  &__Bars {
    display: flex;
    flex-direction: column;
    .bar {
      background-color: #000;
      position: relative;
      -webkit-transform-origin: center center;
      transform-origin: center center;
      transition: all 0.2s;
    }
    .bar1 {
      width: 16px;
      height: 2px;
      margin: 0 0 2px 0;
      left: 0;
    }
    .bar2 {
      width: 28px;
      height: 2px;
      margin: 2px 0;
    }
    .bar3 {
      width: 16px;
      height: 2px;
      left: 16px;
      margin: 2px 0 0 0;
    }
  }
}

.mobileMenu__Box.active .bar1 {
  left: 16px;
}
.mobileMenu__Box.active .bar3 {
  left: 0;
}

.logo {
    text-transform: uppercase;
}

.menu {
  position: fixed;
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  z-index: 100;
  top: -100vh;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  text-align: center;
  padding-top: 80px;
  transition: all 0.5s;
  
  &.active {
    transition: all 0.5s;
      top: 0
  }

  .menuItem {
    > a {
      display: block;
      padding: 10px 0;
      font-size: 1.3rem;
      text-transform: uppercase;
    }
    .menuItem2__container {
        padding-bottom: 20px;
    }
  }

  .menuItem2 {
    display: flex;
    flex-direction: column;

    a {
      padding: 5px 0px;
      position: relative;
      // text-transform: uppercase;
      font-size: 0.775rem;
      letter-spacing: 0.05em;
      font-weight: 300;
      opacity: 0.8;
      transition: all 0.5s;
    }
  }
}

@media screen and (min-width: 40rem) {
  .mobileMenu {
    display: none;
  }
  .header {
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    .menu > span > a {
      position: relative;
      text-transform: uppercase;
      font-size: 0.875rem;
      letter-spacing: 0.05em;
      padding: 0.5rem 0;
      font-weight: 700;
      transition: color 0.5s;
    }
    .logo {
      text-transform: uppercase;
      display: block;
      margin-bottom: 1.5rem;
      padding: 0.5rem 0;
    }
  }

  .menu {
      top: 0;
    opacity: 1;
    position: relative;
    height: auto;
    width: auto;
    flex-direction: row;
    padding-top: 0;
    .menuItem {
      position: relative;
    }
    .menuItem > a {
      position: relative;
      margin: 0 0.75rem;

      &[aria-current]:before {
        -webkit-transform: scaleX(1);
        -ms-transform: scaleX(1);
        transform: scaleX(1);
      }

      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 2px;
        background-color: currentColor;
        -webkit-transform: scaleX(0);
        -ms-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: left;
        -ms-transform-origin: left;
        transform-origin: left;
        -webkit-transition: -webkit-transform 0.4s
          cubic-bezier(0.15, 0.85, 0.45, 1);
        transition: -webkit-transform 0.4s cubic-bezier(0.15, 0.85, 0.45, 1);
        transition: transform 0.4s cubic-bezier(0.15, 0.85, 0.45, 1);
        transition: transform 0.4s cubic-bezier(0.15, 0.85, 0.45, 1),
          -webkit-transform 0.4s cubic-bezier(0.15, 0.85, 0.45, 1);
      }

      &:hover:before {
        -webkit-transform: scaleX(1);
        -ms-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-transform-origin: right;
        -ms-transform-origin: right;
        transform-origin: right;
        -webkit-transition: -webkit-transform 0.6s
          cubic-bezier(0.77, 0, 0.18, 1);
        transition: -webkit-transform 0.6s cubic-bezier(0.77, 0, 0.18, 1);
        transition: transform 0.6s cubic-bezier(0.77, 0, 0.18, 1);
        transition: transform 0.6s cubic-bezier(0.77, 0, 0.18, 1),
          -webkit-transform 0.6s cubic-bezier(0.77, 0, 0.18, 1);
        z-index: 999999;
      }
    }
    .menuItem2__container {
      transform: scale(0);
      transform-origin: top;
      overflow: hidden;
      position: absolute;
      left: 0;
      transition: all 0.3s;
      .menuItem2 {
        background-color: #fff;
        border: 1px solid black;
        margin-top: 15px;
        a {
          padding: 10px 15px;

          &:hover {
            background-color: #000;
            color: #fff;
          }
        }
      }
    }
    .menuItem:hover .menuItem2__container {
      transition: all 0.5s;
      transform: scale(1);
      z-index: 99999999;
    }
  }
}
// Ende Breakpoint Menu

// Impressum-Menü

.menuSecond {
  border-top: 2px solid #000;
  width: 16.5rem;
  margin: 0 auto;
}

.menuSecond > a {
  text-transform: uppercase;
  font-weight: bold;
  padding: 20px 15px;
}

// Breakpoints

@media screen and (min-width: 40rem) {
  .header .logo {
    margin-bottom: 0;
    font-size: 2rem;
    letter-spacing: 0rem;
    font-weight: normal;
  }
  .header {
    flex-direction: row;
    justify-content: space-between;
  }
  .menu {
    margin-right: -0.75rem;
  }
}
