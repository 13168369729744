#Overlayer figcaption p:nth-of-type(1) {
    margin-top: 5px;
    font-weight: bold;
}


.Overlayer {
    position: fixed;
    opacity: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    max-width: 100vw !important;
    height: 0;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 99999;
    overflow: hidden;
    transition: opacity 0.8s;
    opacity: 0;
    
}

/* fade in and out */

.Overlayer.active{
    opacity: 1;
    /* transition: opacity 0.5s; */
    height: 100%;
}

.Overlayer__inner {
    position: relative;
    width: 100%;
    height: 100%;
}

.Overlayer__inner__slider {
    height: 100%;
    width: 100%;
}

/* .Overlayer.active .Overlayer__inner {
    opacity: 1;
} */

.Overlayer__close {
    position: absolute;
    top: 10px;
    right: 20px;
    z-index: 99999;
    height: 30px;
    width: 30px;
    background-color: #fff;
    border-color: transparent;
    border-radius: 100%;
    transition: background-color, 0.3s;
    overflow: hidden;
}

.Overlayer__close:hover {
    background-color: #000;
}

.Overlayer__close:hover .close {
    color: #fff;
}


.close {
    color: #000;
    font-size: 25px;
    height: 25px;
    font-weight: bold;
    transition: color 0.3s;
    line-height: 1;
}

.close:focus {
    color: #fff;
}