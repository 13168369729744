:root {
    --swiper-theme-color: #585858;
  }

.swiper-container {
    width: 100%;
    height: 100%;
    display: flex;
}

.swiper-wrapper {
}


.swiper-slide {
    display: flex;
    flex-direction: column;
}

.swiper-bgimage {
    width: calc(100% - 40px - (var(--swiper-navigation-size) / 44 * 27)*2);
    margin: 0 auto;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.swiper-text {
    margin-bottom: 40px;
    padding-top: 10px;
    text-align: center;
}

.swiper-text p:first-of-type {
    font-weight: 500;
    margin-bottom: 5px;
}

.swiper-text p:nth-of-type(2) {
    font-weight: 100;
}