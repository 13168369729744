@import '../node_modules/swiper/swiper-bundle.css';
@import 'submodules/slider.css';
@import 'submodules/overlayer.css';
.mobileMenu {
  display: block;
}
.mobileMenu__Box {
  position: absolute;
  top: 40px;
  right: 20px;
  width: 33px;
  height: 18px;
  outline: none;
  background-color: transparent;
  border: 0;
  z-index: 99999;
}
.mobileMenu__Bars {
  display: flex;
  flex-direction: column;
}
.mobileMenu__Bars .bar {
  background-color: #000;
  position: relative;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  transition: all 0.2s;
}
.mobileMenu__Bars .bar1 {
  width: 16px;
  height: 2px;
  margin: 0 0 2px 0;
  left: 0;
}
.mobileMenu__Bars .bar2 {
  width: 28px;
  height: 2px;
  margin: 2px 0;
}
.mobileMenu__Bars .bar3 {
  width: 16px;
  height: 2px;
  left: 16px;
  margin: 2px 0 0 0;
}
.mobileMenu__Box.active .bar1 {
  left: 16px;
}
.mobileMenu__Box.active .bar3 {
  left: 0;
}
.logo {
  text-transform: uppercase;
}
.menu {
  position: fixed;
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  z-index: 100;
  top: -100vh;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  text-align: center;
  padding-top: 80px;
  transition: all 0.5s;
}
.menu.active {
  transition: all 0.5s;
  top: 0;
}
.menu .menuItem > a {
  display: block;
  padding: 10px 0;
  font-size: 1.3rem;
  text-transform: uppercase;
}
.menu .menuItem .menuItem2__container {
  padding-bottom: 20px;
}
.menu .menuItem2 {
  display: flex;
  flex-direction: column;
}
.menu .menuItem2 a {
  padding: 5px 0px;
  position: relative;
  font-size: 0.775rem;
  letter-spacing: 0.05em;
  font-weight: 300;
  opacity: 0.8;
  transition: all 0.5s;
}
@media screen and (min-width: 40rem) {
  .mobileMenu {
    display: none;
  }
  .header {
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .header .menu > span > a {
    position: relative;
    text-transform: uppercase;
    font-size: 0.875rem;
    letter-spacing: 0.05em;
    padding: 0.5rem 0;
    font-weight: 700;
    transition: color 0.5s;
  }
  .header .logo {
    text-transform: uppercase;
    display: block;
    margin-bottom: 1.5rem;
    padding: 0.5rem 0;
  }
  .menu {
    top: 0;
    opacity: 1;
    position: relative;
    height: auto;
    width: auto;
    flex-direction: row;
    padding-top: 0;
  }
  .menu .menuItem {
    position: relative;
  }
  .menu .menuItem > a {
    position: relative;
    margin: 0 0.75rem;
  }
  .menu .menuItem > a[aria-current]:before {
    -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1);
  }
  .menu .menuItem > a:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background-color: currentColor;
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: left;
    -ms-transform-origin: left;
    transform-origin: left;
    -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.15, 0.85, 0.45, 1);
    transition: -webkit-transform 0.4s cubic-bezier(0.15, 0.85, 0.45, 1);
    transition: transform 0.4s cubic-bezier(0.15, 0.85, 0.45, 1);
    transition: transform 0.4s cubic-bezier(0.15, 0.85, 0.45, 1), -webkit-transform 0.4s cubic-bezier(0.15, 0.85, 0.45, 1);
  }
  .menu .menuItem > a:hover:before {
    -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: right;
    -ms-transform-origin: right;
    transform-origin: right;
    -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.77, 0, 0.18, 1);
    transition: -webkit-transform 0.6s cubic-bezier(0.77, 0, 0.18, 1);
    transition: transform 0.6s cubic-bezier(0.77, 0, 0.18, 1);
    transition: transform 0.6s cubic-bezier(0.77, 0, 0.18, 1), -webkit-transform 0.6s cubic-bezier(0.77, 0, 0.18, 1);
    z-index: 999999;
  }
  .menu .menuItem2__container {
    transform: scale(0);
    transform-origin: top;
    overflow: hidden;
    position: absolute;
    left: 0;
    transition: all 0.3s;
  }
  .menu .menuItem2__container .menuItem2 {
    background-color: #fff;
    border: 1px solid black;
    margin-top: 15px;
  }
  .menu .menuItem2__container .menuItem2 a {
    padding: 10px 15px;
  }
  .menu .menuItem2__container .menuItem2 a:hover {
    background-color: #000;
    color: #fff;
  }
  .menu .menuItem:hover .menuItem2__container {
    transition: all 0.5s;
    transform: scale(1);
    z-index: 99999999;
  }
}
.menuSecond {
  border-top: 2px solid #000;
  width: 16.5rem;
  margin: 0 auto;
}
.menuSecond > a {
  text-transform: uppercase;
  font-weight: bold;
  padding: 20px 15px;
}
@media screen and (min-width: 40rem) {
  .header .logo {
    margin-bottom: 0;
    font-size: 2rem;
    letter-spacing: 0rem;
    font-weight: normal;
  }
  .header {
    flex-direction: row;
    justify-content: space-between;
  }
  .menu {
    margin-right: -0.75rem;
  }
}
:root {
  --content-width: 65rem;
}
*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  scroll-behavior: smooth;
}
li {
  list-style: none;
}
.text ul {
  margin: 0 0 0 30px;
  padding: 0;
}
.text ul li {
  margin: 0;
  padding: 0;
}
a {
  color: currentColor;
  text-decoration: none;
}
strong,
b {
  font-weight: 500;
}
a:focus,
button:focus,
div:focus {
  outline: none !important;
}
/* 
img {
  width: 100%;
} */
.page {
  padding: 5vh 5vw 10vh;
}
.page > * {
  max-width: var(--content-width);
  margin: 0 auto;
}
main {
  min-height: calc(100vh - 10rem);
}
.intro {
  padding: 10vh 0;
  text-align: center;
}
.intro h1,
h1 {
  position: relative;
  margin-bottom: 1rem;
  font-weight: 300;
  text-transform: uppercase;
  font-size: 1.8rem;
  z-index: 1;
}
.tags {
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.075em;
  font-size: 0.75rem;
  font-weight: 600;
}
.text {
  line-height: 1.5em;
}
.text p,
.text figure,
.text ul,
.text ol {
  margin-bottom: 1.5em;
}
.text h2 {
  font-size: 1.5rem;
  font-weight: 700;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
  text-align: left;
}
.text > *:first-child {
  margin-top: 0;
}
.text a {
  position: relative;
  white-space: nowrap;
  font-weight: 500;
  z-index: 1;
  display: inline-block;
  margin-bottom: 20px;
}
.text a::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  height: 2px;
  background-color: #000;
  transition: all 0.3s;
  width: 0%;
  opacity: 0;
}
.text a.is-checked::after {
  width: 100%;
  opacity: 0.9;
}
.text img {
  width: 100%;
}
.footer {
  padding: 1.5rem 5vw 10vh;
  text-align: center;
  max-width: var(--content-width);
  margin: 0 auto;
  line-height: 1.5em;
}
.footer a {
  display: inline-block;
  font-size: 0.875rem;
}
.footer > a {
  margin-bottom: 3.5rem;
  /* border-top: 2px solid #000; */
  /* width: 16.5rem; */
  padding-top: 0.5rem;
}
.social a {
  margin: 0 0.75rem;
  padding: 0.5rem 1rem;
  border: 2px solid #000;
  width: 7.5rem;
}
.social a:hover {
  background: #000;
  color: #fff;
}
.cursor {
  cursor: pointer;
}
.ccm__content__heading p {
  margin-top: 15px;
}
.honeypot {
  position: absolute;
  left: -9999px;
}
/* Layout fuer ueber und kontakt  */
.layout {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 3rem;
}
@media screen and (min-width: 45rem) {
  .layout {
    grid-template-columns: 1fr 2fr;
  }
}
@media screen and (max-width: 45rem) {
  .text {
    grid-row-start: 1;
  }
}
.layout aside section {
  margin-bottom: 3rem;
}
.layout aside h2 {
  margin-bottom: 0.75rem;
}
.layout .text h1 {
  margin-bottom: 4rem;
}
.animated__container {
  opacity: 0;
}
.particles-js-canvas-el {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  opacity: 0.2;
}
.highlight {
  background: -webkit-linear-gradient(45deg, #d5007d, #e53935);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
